<template>
  <!--begin::Content-->
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <b-container>
        <!--begin::Card-->
        <b-card title="Card Title" no-body>
          <b-card-header header-tag="nav">
            <b-nav card-header tabs>
              <b-nav-item
                :to="{ name: 'category.partner' }"
                exact
                exact-active-class="active"
                >Partenaire</b-nav-item
              >
              <b-nav-item
                :to="{ name: 'category.post' }"
                exact
                exact-active-class="active"
                >Article</b-nav-item
              >
              <b-nav-item
                :to="{ name: 'category.typeTag' }"
                exact
                exact-active-class="active"
                >Type de Tag</b-nav-item
              >
            </b-nav>
          </b-card-header>

          <b-card-body>
            <router-view></router-view>
          </b-card-body>
        </b-card>
      </b-container>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</template>

<script>
export default {
  name: "Index"
};
</script>

<style scoped></style>
